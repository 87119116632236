@use '@angular/material' as mat;
@use './stylesm3-theme.scss' as md;

.light {
  --md-sys-color-primary: rgb(85 89 146);
  --md-sys-color-surface-tint: rgb(85 89 146);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(224 224 255);
  --md-sys-color-on-primary-container: rgb(17 20 75);
  --md-sys-color-secondary: rgb(85 90 146);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(224 224 255);
  --md-sys-color-on-secondary-container: rgb(16 20 75);
  --md-sys-color-tertiary: rgb(132 76 114);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(255 216 237);
  --md-sys-color-on-tertiary-container: rgb(54 7 44);
  --md-sys-color-error: rgb(144 74 67);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(59 9 7);
  --md-sys-color-background: rgb(251 248 255);
  --md-sys-color-on-background: rgb(27 27 33);
  --md-sys-color-surface: rgb(251 248 255);
  --md-sys-color-on-surface: rgb(27 27 33);
  --md-sys-color-surface-variant: rgb(228 225 236);
  --md-sys-color-on-surface-variant: rgb(71 70 79);
  --md-sys-color-outline: rgb(119 118 128);
  --md-sys-color-outline-variant: rgb(200 197 208);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(48 48 54);
  --md-sys-color-inverse-on-surface: rgb(242 239 247);
  --md-sys-color-inverse-primary: rgb(190 194 255);
  --md-sys-color-primary-fixed: rgb(224 224 255);
  --md-sys-color-on-primary-fixed: rgb(17 20 75);
  --md-sys-color-primary-fixed-dim: rgb(190 194 255);
  --md-sys-color-on-primary-fixed-variant: rgb(62 66 120);
  --md-sys-color-secondary-fixed: rgb(224 224 255);
  --md-sys-color-on-secondary-fixed: rgb(16 20 75);
  --md-sys-color-secondary-fixed-dim: rgb(190 194 255);
  --md-sys-color-on-secondary-fixed-variant: rgb(61 66 121);
  --md-sys-color-tertiary-fixed: rgb(255 216 237);
  --md-sys-color-on-tertiary-fixed: rgb(54 7 44);
  --md-sys-color-tertiary-fixed-dim: rgb(248 177 221);
  --md-sys-color-on-tertiary-fixed-variant: rgb(105 52 89);
  --md-sys-color-surface-dim: rgb(219 217 224);
  --md-sys-color-surface-bright: rgb(251 248 255);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(245 242 250);
  --md-sys-color-surface-container: rgb(239 237 244);
  --md-sys-color-surface-container-high: rgb(234 231 239);
  --md-sys-color-surface-container-highest: rgb(228 225 233);

  @include mat.all-component-colors(md.$light-theme); 
}
