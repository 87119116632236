/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use './assets/styles/themes/stylesm3-theme.scss' as md;

@use './assets/styles/themes/light.scss' as *;
@use './assets/styles/themes/dark.scss' as *;

/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

$spacing: 1.2rem;
$header-size: 64px;
$border-radius: 5px;

@include mat.core();

:root {
	--mat-divider-color: color-mix(in srgb, var(--md-sys-color-on-primary-container) 20%, transparent);

	body {
		background: var(--md-sys-color-background);
		color: var(--md-sys-color-on-background);
	}
	
	// Apply adjusted styles on components
	@include mat.button-overrides((container-shape: $border-radius));
	@include mat.checkbox-base((container-shape: $border-radius));
	.mat-accent.mat-mdc-checkbox {
		// --mdc-checkbox-selected-focus-icon-color: var(--md-sys-color-primary) !important;
    // --mdc-checkbox-selected-hover-icon-color: var(--md-sys-color-primary) !important;
    // --mdc-checkbox-selected-focus-state-layer-color: var(--md-sys-color-primary) !important;
    // --mdc-checkbox-selected-hover-state-layer-color: var(--md-sys-color-primary) !important;
    // --mdc-checkbox-unselected-pressed-state-layer-color: var(--md-sys-color-primary) !important;
		// --mdc-checkbox-selected-icon-color: var(--md-sys-color-primary) !important;
    --mat-checkbox-label-text-color: var(--md-sys-color-on-surface) !important;
	}

	.mat-mdc-card {
		--mdc-elevated-card-container-color: var(--md-sys-color-inverse-on-surface);
	}
	// @include mat.checkbox-overrides((label-text-color: red));
	// Give mat-icon-button shape at 5px
	.mat-mdc-icon-button * { border-radius: $border-radius !important; };
	@include mat.sidenav-overrides((
		container-shape: $border-radius,
		));
	.mat-drawer, .mat-drawer-side {
		border-inline: 1px solid color-mix(in srgb, var(--md-sys-color-on-primary-container) 20%, transparent);
	}
	@include mat.toolbar-overrides((
		standard-height: 3.5rem,
	));
	.mat-toolbar { background: var(--md-sys-color-primary-container) };
	@include mat.dialog-overrides((
		container-shape: 8px,
	));
	@include mat.tree-overrides((
		node-text-size: 0.9rem,
		node-min-height: 2.8rem,
	));
}

* { 
	scrollbar-width: thin; 
	scrollbar-color: var(--md-sys-color-primary-container) var(--md-sys-color-background); 
}
[cdkMenu] { 
	background: var(--md-sys-color-surface-container);
	display: flex;
	flex-direction: column;
	width: 12rem;
	padding: 1.2px;
	button {
		justify-content: flex-start;
	}
}
button mat-icon, a mat-icon, .selectable mat-icon, mat-toolbar mat-icon {
	color: var(--md-sys-color-primary) !important;
}
.selected {
	background-color: var(--md-sys-color-primary-container) !important;
	outline: var(--md-sys-color-on-primary) !important;
	outline-offset: -1px;
}
.selectable {
	&:hover {
		cursor: pointer;
		background: var(--md-sys-color-primary-container);
	}
}
.cdk-drag-preview { color: var(--md-sys-color-on-background); }
.warning { background-color: var(--md-sys-color-error-container); }
.overlay { background: var(--md-sys-color-surface-container); }

.primary-color { color: var(--md-sys-color-primary) }
.secondary-color { color: var(--md-sys-color-secondary) }
.tertiary-color { color: var(--md-sys-color-tertiary) }
.warn-color { color: var(--md-sys-color-error) }

[mat-tab-link] {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

[mat-dialog-title] {
	mat-icon {
		line-height: 2rem;
		margin: 0.4rem;
	}
}

body { 
	margin: 0; 
	box-sizing: border-box;
	overflow: hidden;
}

button {
	> * {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

mat-dialog-content {
	> mat-form-field {
		width: 100%;
		margin-bottom: 0.8rem;
	}
}

router-outlet ~ * {
	position: initial;
	// height: 100%;
	// Height without header
	height: calc(100% - 104px);
	width: 100%;
	max-width: 100%;
}

.text-line {
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
}

.container {
	display: flex;
	flex-direction: column;
	gap: $spacing;
	margin: $spacing;
	box-sizing: border-box;
	position: relative;
	// width: 100%;

	> mat-progress-bar {
		position: absolute;
    top: -$spacing;
    left: -$spacing;
    width: calc(100% + ($spacing * 2));
	}
}

.button-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: $spacing;

	&.end {
		justify-content: flex-end;

		display: flex;
		flex-direction: row;
		gap: $spacing;
	}

	& > .start {
		margin-right: auto;
		gap: $spacing;
		display: flex;
		flex-direction: row;
	}

	& > .end {
		margin-left: auto;
		gap: $spacing;

		display: flex;
		flex-direction: row;
		gap: 0.4rem;
	}
}

button[mat-icon-button] {
	$large-size-button: 80px;
	$large-size-icon: 48px;
	
			&.icon-button-large {
				width: $large-size-button;
				height: $large-size-button;
				line-height: $large-size-button;
			.mat-icon {
				font-size: $large-size-icon;
				width: $large-size-icon;
				height: $large-size-icon;
				line-height: $large-size-icon;
			}
			.mat-button-ripple {
				font-size: inherit;
				width: inherit;
				height: inherit;
				line-height: inherit;
			}
		}
	
		$small-size-button: 24px;
		$small-size-icon: 18px;
	
			&.icon-button-small {
				width: $small-size-button;
				height: $small-size-button;
				line-height: $small-size-button;
			.mat-icon {
				font-size: $small-size-icon;
				width: $small-size-icon;
				height: $small-size-icon;
				line-height: $small-size-icon;
			}
			.mat-button-ripple {
				font-size: inherit;
				width: inherit;
				height: inherit;
				line-height: inherit;
			}
		}
	}

// For menus
.large {
	min-width: 20rem !important;
	transform: scale(1.2);

}
.scrollable {
	max-height: 20rem !important;
}
// For edit button
.small {
	transform: scale(0.85);
}
.smallest {
	transform: scale(0.7);
}

.discreet {
	opacity: 0.5;
}

// For mat-menus
.menu-form-wrapper .mat-menu-panel {
  max-width: initial !important;
}

.mat-mdc-menu-content {
	padding-inline: 0.4rem !important;
}
.skeleton {
	background-color: var(--md-sys-color-on-primary-container);
}

.full-screen-modal .mat-dialog-container {
  padding: 1em;
  width: 100%;
  height: 100%;
}

// * For tables
th, td {
	left: 0 !important;
}

th.mat-column-position, td.mat-column-position {
  padding-left: 8px;
}

.mat-mdc-table-sticky-border-elem-right {
  border-left: 1px solid var(--mat-table-row-item-outline-color, var(--mat-app-outline, rgba(0, 0, 0, 0.12)));
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid var(--mat-table-row-item-outline-color, var(--mat-app-outline, rgba(0, 0, 0, 0.12)));
}

.table-container {
	overflow: auto;
}

.mat-mdc-table-sticky {
  opacity: 1;
}

.readonly, [readonly] {
	pointer-events: none;
	background-color: #d3d3d370;
}